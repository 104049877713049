@use 'src/Sass/variables' as v;

.login {
    min-height: 90vh;
    width: 90%;
    margin: 0 auto;
}

.login .form {
    background-color: white;
    max-width: 576px;
    margin: 50px auto;
    min-height: 650px;
    padding: 50px;
    border-radius: 30px;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.login .form button {
    width: 100%;
}

.login h2 {
    margin-bottom: 30px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .login .form {
        padding: 20px;
    }

    .login h2 {
        font-size: 30px;
    }

    .login p {
        font-size: 16px;
    }

    .login .form {
        background-color: transparent;
        max-width: 95%;
        padding: 0px;
        box-shadow: none;
    }
}