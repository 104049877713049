@use 'src/Sass/variables' as v;

 .howItWorks h2,
 .howItWorks h1 {
    color: v.$d-blue2;
    margin: 0 auto;
}

.howItWorks h4 {
  color: v.$d-blue2;
}

.sectionOne {
    padding-bottom: 300px;
}

.sectionOne h1 {
    max-width: 1000px;
    line-height: 1.5;
    font-size: 45px;
}

.sectionOne img {
    width: 100%;
}

.howItWorks h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.5;
}

.sectionOne h2.subtitle, .howItWorks h3 {
    color: v.$d-blue2;
    font-size: 18px;
    font-weight: normal;
}

.partner .sectionOne{
    background-color: rgba(35, 205, 170, 0.1);
}

.applicant .sectionOne{
    background-color:  #CFF1FB;
}

.student .sectionOne{
    background-color:  rgba(225, 241, 37, 0.14);
}

.sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    padding-top: 5em;
}

.sectionOne .grid div:first-of-type{
    margin-top: 100px;
    margin-left: 50px;
}

.sectionTwo .container {
    background-color: white;
    border-radius: 24px;
    margin-top: -5%;
    padding: 50px 0 0px 0;
    width: 85%;
    margin: -150px auto 0 auto;
}

.sectionTwo .grid {
    display: grid;
    margin: 0 auto;
    width: 95%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.sectionTwo .grid img {
    display: block;
    margin: 0 auto 10px auto;
    width: 70px;
}

.toggleGrid .grid {
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-bottom: 50px;
    column-gap: 50px;
}

 .toggleGrid {
    width: 80%;
    margin: 0 auto;
}

.toggleGrid .grid span{
    grid-row: 1;
}

.toggleGrid .grid .stagger{
    grid-column: 2;
}

.toggleGrid .grid .stagger, .toggleGrid .grid .step {
    display:flex;
    align-items: center;
}

.howItWorks .toggleGrid img {
    width: 100% !important;
}

.step p {
    width: 100%;
}

.preFooter {
    padding-bottom: 230px;
}

.partner .gridThreeCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .gridTwoCol {
    display: grid;
    width: 80%;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}
.partner .card {
    background-color: v.$green;
    color: white;
    margin: 0 auto;
    display: block;
}

.partner .gridThreeCol .card {
   width: 300px;
}

.partner .gridTwoCol .card {
    width: 350px;
}

 .partner li, .partner .card p {
    text-align: center !important;
 }
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 850px) {
    .sectionOne h1,
    .sectionOne h2.subtitle,
    .howItWorks h3,
    .toggleGrid p,
    .toggleGrid h4 {
        text-align: center;
    }

    .howItWorks button {
        display: block;
        margin: 0 auto;
    }

    .howItWorks .sectionOne {
        padding-bottom: 200px;
    }

    .howItWorks h1, .howItWorks h2 {
        font-size: 28px;
    }

    .sectionOne .grid div:first-of-type{
        margin: 0 auto 50px auto;
        width: 95%;
    }

    .sectionOne button, .toggleGrid .grid img {
        display: block;
        margin: 0 auto;
    }

    .sectionOne a {
        display: flex;
    }

    .sectionTwo .grid,
    .sectionOne .grid,
    .toggleGrid .grid,
    .partner .gridThreeCol ,
    .partner .gridTwoCol  {
        display: block;
    }

    .sectionTwo .container {
        width: 100%;
        border-radius: 0 ;
        padding: 50px 0 20px 0;
    }

    .toggleGrid {
        width: 95%;
    }

    .toggleGrid .grid .container {
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 50px 0px;
    }

    .partner .card {
        display: block;
        margin: 20px auto;
    }

    .partner .gridThreeCol,
    .partner .gridTwoCol  {
        width: 90%;
    }

    .card.pricing {
        border-radius: 0;
    }
}
