@use 'src/Sass/variables' as v;

.landing .nav a {
    color: white !important;
}

.landing .container {
    width: 90%;
    margin: 0 auto;
}

.landing .sectionOne {
    background-color: v.$l-blue;
    background: linear-gradient(110.54deg, #F5F5F5 -25.69%, #0D8AFE 7.86%, #8468F3 83.53%);
    padding-bottom: 105px;
}

.landing .sectionOne .hero {
    margin-top: 8%;
}

.landing .sectionOne .grid > span {
    margin-left: 100px;
}
.landing .sectionOne .action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.landing h2,
.landing h1 {
    color: v.$d-blue2;
    margin: 0 auto;
    text-align: center;
}

.landing .sectionOne h1 {
    max-width: 1000px;
    line-height: 1.5;
    font-size: 50px;
    text-align: left;
    color: white;
}

.landing .sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    padding: 3% 0 0% 0;
}

.landing h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.5;
}

.landing h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.005em;
    color:  v.$d-blue2;
}

.landing p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
}

 .landing h3 {
    color: v.$d-blue2;
    font-size: 18px;
    text-align: center;
}

.sectionOne h2.subtitle {
    margin: 30px 0px;
    font-weight: normal;
    max-width: 700px;
    text-align: left;
    color: white;
    font-size: 14px;
}

.landing .search {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
}

.landing .stats {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing .sectionOne .stat p {
    margin: 0;
    text-align: center;
    white-space: nowrap;
    color: white;
}

.landing .stat p:first-of-type {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.005em;
}

.landing .stat p:nth-of-type(2) {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.005em;
}

.landing .stat:nth-of-type(2) {
    border-left: 1px solid white;
}

.landing .stat {
    padding-left: 2em;
    padding-right: 2em;
}

.landing .stat:first-of-type {
    padding-left: 0em;
}

.landing button.inactive {
    background-color: #F9F9FA;
    border: 1px solid #F9F9FA;
    color: v.$d-blue2;
}

.landing .sectionTwo .container {
    background-color: white;
    border-radius: 24px;
    margin-top: -5%;
    padding: 70px 0 70px 0;
}

.landing .sectionTwo .grid {
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-bottom: 50px;
}

.landing .sectionTwo .gridContainer {
    width: 80%;
    margin: 0 auto;
}

.landing .sectionTwo .grid span{
    grid-row: 1;
    margin-left: 30px;
    margin-right: 30px;
    margin-top:20px
}

.landing .sectionTwo .stagger{
    grid-column: 2;
}


.landing .sectionTwo .step , .sectionTwo .stagger{
    display: flex;
    align-items: center;
}

.step img, .stagger img {
    height: 70px;
    margin-right: 10px;
}

.step p {
    width: 100%;
}

.landing .sectionThree .grid{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 85%;
    padding: 100px 0 0 0;
    position: relative;
    column-gap: 60px;
}

.landing .sectionThree .grid:nth-of-type(2) div:first-of-type{
    grid-column: 2;
    grid-row: 1;
}

.landing .sectionThree .grid:nth-of-type(2) div:nth-of-type(2){
    grid-column: 1;
    grid-row: 1;
}

.sectionThree .img2 {
    position: absolute;
}

.landing .sectionThree h2,
.landing .sectionThree h3,
.landing .sectionFour h2{
    text-align: left;
}

.landing .sectionThree .stat p {
    text-align: left;
    width: 320px;
}

.landing .sectionFour {
    background-color: white;
    padding: 100px 0 150px 0;
    position: relative;
}

.landing .sectionFour .grid{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 30px;
    text-align: center;
}

.uniTile p:first-of-type {
    color: v.$d-blue2;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.uniTile a {
    color: v.$d-blue;
    border-color: v.$d-blue;
    padding: 10px 15px;
}

.uniTile img {
    border-radius: 20px;
    width: 100%;
    height: 250px;
    object-fit: cover;
}


.sectionFour a.discover {
    position: absolute;
    top: 100px;
    right: 10%;
}

.landing .sectionFour .school {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: v.$d-blue2;
    letter-spacing: 0.005em;
}

.landing .sectionFive .header {
  padding-top: -1000px;
}

.landing .sectionFive {
    padding: 0 0 0 0;
    background-color: white;
}

.landing .sectionFive .grid {
    background-image: url("https://cdn.cassy.dev/landing_bg_2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 150px 100px 50px 100px;
}

.landing .sectionFive .testimonyCarousel {
  display: flex;
  flex-direction: row;
}

.landing .sectionFive .testimonyArrow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.sectionFive .quote {
    font-size: 20px;
    line-height: 1.5;
    color: white;
    margin-top: 30px;
}

.preFooter {
    padding: 100px 0 300px 0;
}

.landing .sectionSix .grid {
    display: grid;
    margin: 0 auto;
    width: 80%;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    column-gap: 70px;
}

.landing .sectionSix h4 {
    font-weight: 600;
    font-size: 38px;
    line-height: 54px;
}

.sectionSix img {
    max-height: 500px;
}

.crop {
    overflow: hidden;
    width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
}

.crop img {
    margin: 0 auto;
    display: block;
    position: relative;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .landing .sectionFive .grid {
      padding: 150px 20px 50px 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {

  .landing .sectionFive .grid {
      padding: 50px 20px 50px 20px;
  }

  .landing .sectionFive .testimonyCarousel {
    padding-top: 50px;
  }
    .landing .sectionOne .grid {
        display: block;
    }

    .landing .sectionOne .image {
        text-align: center;
        margin-top: 50px;
     }

     .landing .sectionTwo .grid span{
        display: block;
        margin: 0 auto;
    }

     .landing .step img,  .landing .stagger img{
        display: none;
    }

    .landing .sectionOne h2 {
        display: none;
    }

    .landing .sectionThree h2,
    .landing .sectionThree h3,
    .landing .sectionFour h2{
        text-align: center;
    }


    .landing .sectionSix h4 {
        font-size: 32px;
    }

    .landing .sectionOne h1 {
        font-size: 35px;
        text-align: center;
        width: 95%;
        padding-bottom: 20px;
    }

    .landing .sectionOne .grid span {
        width: 95%;
        margin: 0 auto;
    }

    .landing .searchContainer {
        width: 96%;
        padding-bottom: 20px;
    }

    .landing .sectionOne {
        padding-bottom: 50px;
    }

    .landing .search,
    .landing .sectionTwo .grid,
    .landing .sectionThree .grid,
    .landing .sectionFour .grid,
    .landing .sectionFive .grid,
    .landing .sectionSix .grid,
    .landing .stats {
        display: block;
    }

    .searchContainer,  .searchContainer .join {
        display: block;
        margin: 20px auto;
    }

    .searchContainer a {
        margin-left: 0 !important;
    }

    .landing .stat:nth-of-type(2) {
        border-left: none !important;
        border-right: none !important;
    }

    .landing .stats {
        padding-top: 30px;
    }

    .landing .stat {
        padding: 0 0;
        margin-bottom: 30px;
    }

    .landing .stat span{
        min-width: 70%;
    }


    .landing .stat p:first-of-type, .subtitle {
        font-size: 24px;
    }

    .landing .sectionOne .stat p {
        white-space: normal;
    }

    .landing .sectionOne .search button,
    .landing .sectionSix button {
        position: relative;
        width: 100%;
    }

    .landing .sectionTwo .container {
        background-color: white;
        border-radius: 0;
        margin-top: 0;
    }

    .landing .sectionTwo .gridContainer {
        width: 95%;
    }

    .landing .sectionTwo .container {
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 50px 0px;
    }

    .landing h3 {
        font-size: 16px;
    }

    .landing .sectionThree .grid {
        padding: 50px 0;
        width: 90%;
    }

    .landing .sectionThree button,
    .landing .sectionThree a,
    .landing .sectionSix a{
        display: block;
        margin: 0 auto;
    }

    .landing .sectionThree .stat p {
        width: auto;
    }

    .landing .sectionFour a.discover {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        position: relative;
        right: 0;
        top: 0;
    }

    .grid img {
        width: 100%;
    }

    .sectionFive .quote {
        font-size: 16px;
        line-height: 1.2;
    }
    .landing .sectionOne .action {
        justify-content: center;
    }

}
