@use 'src/Sass/variables' as v;

strong {
    font-weight: bold;
}
.spinnerLayer {
    background-color: white;
    border-radius: 10px;
    height: 20px;
    width: 70px;
    padding: 16px 0 24px 28px;
}

.dotPulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    color: v.$green;
    box-shadow: 9999px 0 0 -5px;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dotPulse::before, .dotPulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #F6E7D9;
    color: v.$green;
}
.dotPulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dotPulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.dotPulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dotPulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dotPulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dotPulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dotPulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}


.grid {
    background-color: #EEF1F4;
    border-radius: 24px;
    display: grid;
    grid-template-columns: 30% 70%;
    height: 80vh;
}

.threads {
    background-color: white;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    overflow: auto;
}

.threads h3 {
    padding-left: 15px;
    padding-top: 15px;
}

.threadTile {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 15px;
}

.threadTile img {
    height:40px;
    width: 40px;
    object-fit:cover;
    border-radius: 50%
}

.threadTile:hover {
    cursor: pointer;
}


.threadTile.active {
    background-color: v.$grey;
}

.threadClearance {
    margin-bottom: 130px;
}

.threadsContainer {
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.thread {
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-top: 60px;
    overflow: hidden;
}

.header {
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    border-top-right-radius: 24px;
    border-left: 2px solid #EEF1F4;
}

 .header img {
    height:40px;
    width: 40px;
    object-fit:cover;
    border-radius: 50%
}

.threadContent {
    width: 100%;
    padding: 10px;
}

.msgRec, .msgSent {
    display: flex;
}

.msgRec {
    justify-content: left;
}

.msgSent {
    justify-content: flex-end;
}

.msgRec p, .msgSent p {
    padding: 10px;
    border-radius: 10px;
    display:inline-block;
    max-width: 600px;
}

.msgRec p {
    background-color: white;
    border-bottom-left-radius: 0;
}

.msgSent p {
    text-align: end;
    background-color: v.$green;
    color: white;
    border-bottom-right-radius: 0;
}

.input, .mobileInput {
    padding-top: 10px;
    display: grid;
    grid-template-columns: auto 50px;
}

.mobileInput {
    bottom: 10px;
    width: 100%;
   }

.mobile {
    display: none;
}

@media only screen and (max-width: 768px) {
    .web {
        display: none;
    }

    .mobile {
        display: block;
        height: 100%;
        position: relative;
    }

    .threadTile.active {
        background-color: white;
    }

    .grid {
        grid-template-columns: 60px auto;
    }

    .header {
        border-top-left-radius: 24px;
        border-left: 2px solid white;
    }

    .threadContent {
       overflow: scroll;
       max-height: 100%;
    }

    .header {
        position: relative;
    }

    .thread {
        height: 65vh;
        padding-top: 0;
    }

}
