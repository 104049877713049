$l-blue: #CFF1FB;
$d-blue: #162D81;
$blue: #3F9CF2;
$d-blue2: #1E2355;
$green: #23CDAA;
$d-green: #108E73;
$orange: #F45728;
$d-orange: #c54923;
$red: #E26565;
$grey: rgb(212, 221, 230);
$l-grey2: rgba(212, 221, 230,0.3);
$green-hover: #19a88b;
$purple: rgb(118, 120, 237);
$font-1: #1C2D3D;
$font-2: #949DA6;

$h1-color: #1E2355;
$body-color: #444764; 