@use 'src/Sass/variables' as v;

.avi, .logo {
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    position: relative;
}

.logo {
    height: 30px;
    width: 30px;
    margin-right: 8px;
}

.jobHeaderGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
    overflow-x: scroll !important;
    white-space: nowrap !important;
    overflow-y: hidden;
}

.jobHeaderGrid .row {
    min-width: 160px;
}

.jobCard:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

.jobCard {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.grid {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 30px;
    width: 95%;
    margin: 0 auto;
}
    
.thumbnailGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr; 
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.thumbnailGrid img {
    border-radius: 6px;
    aspect-ratio: 1;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.thumbnailGrid img:nth-of-type(1) {
    grid-row: 1/3;
    grid-column: 1/3;
    height: 200px;
}

.thumbnailGrid img:nth-of-type(2) {
    grid-column: 3;
    grid-row: 1;
    height: 95px;
}

.thumbnailGrid img:nth-of-type(3) {
    grid-row: 2;
    grid-column: 3;
    height: 95px;
}

.row {
    min-height: 130px;
    background-color: white;
    border-radius: 24px;
    margin-bottom: 40px;
}

.row p {
    padding: 20px;
    font-size: 14px;
}

@media only screen and (max-width: 960px) { 
    .grid {
        display: block;
    }

    span.text {
        display: none;
    }
}
