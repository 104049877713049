.modal svg {
    cursor: pointer;
}

.modal *{
    color: black;
    border: none;
}

.container img, .container video{
    max-width: 100%;
    max-height: 100%;;
}

.modal .container{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 500px;
}
