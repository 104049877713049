@use 'src/Sass/variables' as v;

.footer .grid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    background-color: v.$d-blue2;
    padding: 100px 0px;
    width: 90%;
    margin: 0 auto;
}


.footer p {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.8);
    width: 300px;
}

.footer p.header {
    font-weight: 600;
    font-size: 14px;
}

.footer button {
    color: white;
    display: block;
}

.footer {
  background-color: v.$d-blue2;
}

.footer .container{
  padding: 500px 0 100px 0;
}

.footer .container:first-of-type {
  background-color: v.$green;
  padding: 80px 0 80px 0;
  border-radius: 24px;
  position: absolute;
}

.footer .gridOne {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 50px;
  width: 80%;
  margin: 0 auto;
  background-color: v.$green;
}

.footer h2 {
  text-align: left;
  color: v.$d-blue2;
  margin-left: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
}

.footer h2:nth-of-type(2) {
  color: white;
}

.footer h3 {
  text-align: left;
  color: white;
  font-weight: normal;
  font-size: 16px;
  margin: 30px auto 30px auto;
  line-height: 1.5;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .footer .gridOne,
  .footer .grid {
    display: block;
  }

  .footer h2 {
    font-size: 32px;
  }

  .footer h3 {
    font-size: 14px;
  }

  .container {
    margin: 0 auto;
  }

  .footer .container:first-of-type {
    border-radius: 0px;
    width: 100%;
    position: relative;

  }

  .footer .container{
    padding: 100px 0 100px 0;
  }

  .container {
    width: 95%;
  }
}
