@use 'src/Sass/variables' as v;

.onboarding {
    height: 100%;
    width: 100%;
    background: #2A52BE;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.onboarding .content {
    overflow-y: scroll;
    background-color: white;
    height: 85%;
    width:90%;
    border-radius: 40px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.onboarding .logo {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    display: block;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
}

.hermesLogo {
  margin-bottom: 30px;
  height: 50px;
}
.question {
    max-width: 80%;
    margin: 0 auto;
    margin-top: -5%;
    text-align: center;
}

.exit {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: bold!important;
}

@media only screen and (max-width: 600px) {
    .onboarding .content {
        width:95%;
    }
    .question {
        max-width: 500px;
        margin: 0 auto;
        margin-top: -10%;
    }

    .onboarding h3 {
        font-size: 20px;
    }

    .onboarding h3, .onboarding .question p {
        text-align: center;
    }
}
