@use 'src/Sass/variables' as v;

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.header .avi {
    height: 50px;
    width: 50px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
}

.nav a, .nav .web a {
    font-size: 14px !important;
}

.header .profile:hover {
    cursor: pointer;
}

.dropdown {
    background-color: transparent !important;
}

.mobile {
    display: none;
}

hr.divider {
    color: #1E2355 !important;
}

@media only screen and (max-width: 960px) {
    .nav a, .nav .web a {
        font-size: 16px !important;
    }


    .web {
        display: none !important;
    }

    .mobile {
        display: block;
    }

    span.profile,
     .searchContainer  {
        display: none !important;
    }
    .title {
        display: block;
        color: white;
    }
    .header {
        background-color: v.$d-green;
    }

    .login {
        margin-bottom: 10px;
    }
}
