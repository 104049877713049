.row {
    min-height: 130px;
}

.blogTile:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

.blogTile {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.search {
    display: none;
}

@media only screen and (max-width: 960px) { 
    .search {
        display: block;
        margin: 0 auto;
    }
}