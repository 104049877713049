@use 'src/Sass/variables' as v;

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.header input{
    max-width: 300px;
}

.header .avi, 
.infoCard .avi  {
    background: url('../../../Static/default-avi.png');
    height: 50px;
    width: 50px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
}

.header .profile:hover {
    cursor: pointer;
}

.card .avi {
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.schedule .avi {
    background: url('../../../Static/default-avi.png');
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    border-radius: 30%;
    margin-bottom: 5px;
    z-index: 5;
    position: relative;
}

.meetingCard, .paymentCard {
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.meetingCard:hover, .paymentCard:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

.statusGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
}

.statusGrid .statusCard {
    min-width: 300px;
}

.statusGrid h2 {
    font-size: 48px;
}

.row {
    min-height: 110px;
    margin-bottom: 30px;
}

div.toggle {
    display: inline-flex;
    background-color: white;
    border-radius: 14px;
}

button.inactive,button.inactive:hover{
    background-color: transparent;
    border-color: transparent;
    color: black;
    font-size: 16px;
}

button.active,
button.active:hover {
    background-color: v.$green;
    border-color: v.$green;
    font-size: 16px;
}

button.inactive:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

button.inactive:nth-of-type(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.meetingListCard {
    margin-left: 3px;
    margin-right: 3px;
}

@media only screen and (max-width: 768px) {
    .statusGrid {
        display: block;
        // overflow-x: scroll !important;
        // white-space: nowrap !important;
        // overflow-y: hidden;
    }

    .statusCard {
        width: 100%;
        display: block !important;
    }

    .statusCard:nth-of-type(2) {
        margin-top: 10px;
    }

    .meetingCard {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 650px) {
    .meetingCard {
        width: 100% !important;
    }
    .meetingListCard {
        margin-left: 0;
        margin-right: 0;
    }
}