@use 'src/Sass/variables' as v;

.grid {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 30px;
    width: 95%;
}

.green {
    color: v.$green;
}

.thumbnailGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr; 
    column-gap: 10px;
    row-gap: 10px;
    margin-bottom: 1em;
    cursor: pointer;
}

.thumbnailGrid img {
    border-radius: 6px;
    aspect-ratio: 1;
    object-fit: cover;
    position: relative;
    width: 100%;
}

.thumbnailGrid img:nth-of-type(1) {
    grid-row: 1/3;
    grid-column: 1/3;
    height: 200px;
}

.thumbnailGrid img:nth-of-type(2) {
    grid-column: 3;
    grid-row: 1;
    height: 95px;
}

.thumbnailGrid img:nth-of-type(3) {
    grid-row: 2;
    grid-column: 3;
    height: 95px;
}

// About

.about {
    width: 100%;
}

.about .row {
    margin-bottom: 30px;
    margin-top: 30px;

}

.tile {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
}

.studentBody {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.stat {
    background-color: rgb(118, 120, 237, 0.2);
    padding: 10px;
    border-radius: 10px;
}

.purple {
    color: v.$purple;
}

.mentorGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.mentorGrid div {
    margin: 0 auto;
}

.timeTile {
    border-radius: 12px;
    padding: 2px 12px;
    display: inline-block;
    color: v.$green;  
    background-color: rgba(41, 182, 151, 0.1);
    border: rgba(41, 182, 151, 0.1);
    margin-right: 1em;
    margin-bottom: 1em;
}

.highlights img, .highlights video {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

//reviews

.summary {
    display: grid;
    grid-template-columns: 1fr 1fr ;
}

.commentGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.scale.active > svg{
    fill: orange;
  }

  // BOOK MEETING
  .mentorCard {
    border: 1px solid rgba(0,0,0,.125) !important;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
    margin: 5px;
  }

  .mentorCard:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

.bookingModal  div {
    max-width: 650px;
}

.aiChatHistoryContainer {
    padding-bottom: 12px;
}

.aiChatHistory {
    background-color: v.$grey;
    border-radius: 8px;
    height: 300px;
    padding: 12px;
    margin-bottom: 12px;
    width: 100%;
    overflow: scroll;
}

.input {
    display: grid;
    grid-template-columns: auto 50px;
}

.msgRec, .msgSent {
    display: flex;
}

.msgRec {
    justify-content: left;
}

.msgSent {
    justify-content: flex-end;
}

.msgRec p, .msgSent p {
    padding: 10px;
    border-radius: 10px;
    display:inline-block;
    max-width: 600px;
}

.msgRec p {
    background-color: white;
    border-bottom-left-radius: 0;
}

.msgSent p {
    text-align: end;
    background-color: v.$green;
    color: white;
    border-bottom-right-radius: 0;
}


button.tileActive {
    color: v.$purple;
    background-color: rgba(118, 120, 237, 0.1);
    border-color: rgba(118, 120, 237, 0.1);
}

button.tileInactive {
    background-color: transparent;
    color: black;
    border: 1px solid v.$grey;
}

@media only screen and (max-width: 960px) { 
    .grid, .summary {
        display: block;
    }
}

@media only screen and (max-width: 768px) { 
    span.text {
        display: none;
    }

    .tab {
        padding: 0 10px;
    }

      // BOOK MEETING
  .mentorCard {
    margin-bottom: 10px !important;
  }
}