.statusGrid, .viewsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    column-gap: 40px;
}

.statGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 40px;
    margin-top: 30px;
}

.statusCard, .statCard {
    min-width: 300px !important;
}

.statusGrid h2 {
    font-size: 48px;
}

.row {
    min-height: 110px;
}

.interviewCard {
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.interviewCard:hover {
    cursor: pointer;
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

.statusGrid,
 .statGrid,
 .viewsGrid,
 .applicantGrid,
.interviewGrid {
    overflow-x: scroll !important;
    white-space: nowrap !important;
    overflow-y: hidden;
}

.applicantGrid > div,
.interviewGrid > div {
    margin: 0 10px;
}

.recruitModal div {
    max-width: 600px;
}

.recruitModal  .active {
    box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.2);
}

@media only screen and (max-width: 768px) {
    .interviewCard {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 650px) {
    .interviewCard {
        width: 100% !important;
    }
}