@use "./variables" as v;
@use "./buttons";
@import "~bootstrap/scss/bootstrap";

.verticalCenter {
  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}

strong{
  font-weight: 600 !important;
}

.progress-bar {
  background-color: v.$d-green !important;
}

h3.heading{
  font-size: 16px;
  font-weight: 600;
}

hr {
  color: #D4DDE6 !important;
}

table tr {
  border: 1px solid transparent;
}

table tr > td {
  padding: 10px;
}

table tr > td:first-of-type {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

table tr > td:last-of-type {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

button, input, .form-label, 
.card-title, .card-body, .card-text, a,
.modal-title, .modal-header, .modal-body {
  font-family: Poppins;
}

.form-label, label {
  font-size: 14px !important;
}

.DayPickerInput > input, textarea.form-control, input.form-control, select.form-control,
.react-datepicker__input-container input, input.rbt-input-main.form-control.rbt-input{
  background-color: white !important;
  color: v.$font-2;
  border-radius: 10px;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input.rbt-input-main {
  background-color: white !important;
  border-radius: 0;
}

.form-check-input[type=checkbox]:checked {
  background-color: v.$green;
  border-color: v.$green;
}

.green {
  color: v.$green;
}

.d-green {
  color: v.$d-green;
}

.purple {
  color: v.$purple;
}

.blue {
  color: v.$blue;
}

h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: 0.005em;
  color:  v.$font-1;
}

h2 {
  color:  v.$font-1;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.005em;
}

h3 {
  font-size: 18px;
  line-height: 28px;
}

div.card {
  border: none;
  border-radius: 15px;
  display: inline-block;
}

img.card-img-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.trunc {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.xlCard {
  width: 100%;
  height: fit-content;
}

.lgCard {
  width: 318px;
}

.medCard{
  width: 16em;
}

.smCard {
  width: 12em;
}

.form-error{
  color: red !important;
  font-size: 12px !important;
  padding: 0 !important;
}

div.modal-content {
  border-radius: 18px;
}

div.modal-header {
  border-bottom: none;
}

//tabs
ul.nav.nav-tabs.spaced {
  justify-content: space-between;
}

ul.nav.nav-tabs  > li.nav-item > .nav-link.active {
  background-color: transparent;
  border-color: v.$green;
  border-bottom: 3px solid v.$green;
  border-top: none;
  border-right: none;
  border-left: none;
}

ul.nav.nav-tabs  > li.nav-item > .nav-link{
  color: black;
}

div.special-label {
  display: none;
}

p.fs-16, span.fs-16, :is(h1, h2, h3, h4, h5, h6).fs-16, a.fs-16, button.fs-16 {
  font-size: 16px !important;
}

p.fs-14, span.fs-14, :is(h1, h2, h3, h4, h5, h6).fs-14, a.fs-14, button.fs-14 {
  font-size: 14px !important;
}

p.fs-13, span.fs-13, div.fs-13, a.fs-13, button.fs-13 {
  font-size: 13px !important;
}

p.fs-10, span.fs-10, :is(h1, h2, h3, h4, h5, h6).fs-10, a.fs-10, button.fs-10 {
  font-size: 10px !important;
}

p.fs-18, span.fs-18, .fs-18{
  font-size: 18px !important;
}

p.fs-12, span.fs-12, a.fs-12, button.fs-12 {
  font-size: 12px !important;
}

p.fs-20, span.fs-20, :is(h1, h2, h3, h4, h5, h6).fs-20 {
  font-size: 20px !important;
}

p.fs-24, span.fs-24, :is(h1, h2, h3, h4, h5, h6).fs-24 {
  font-size: 24px !important;
}

.responsiveGrid, .responsiveGridMd, .responsiveGridSm { 
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
}

.responsiveGridMd { 
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.responsiveGridSm { 
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
}

p {
  white-space: pre-wrap;
}

.horScroll {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .nav-item div.dropdown-menu {
    background-color: transparent;
    border: 1px solid transparent;
  }

  
  h1 {
    font-size: 42px;
  }

  div.card {
    margin: 0 auto;
  }
}