@use 'src/Sass/variables' as v;


.talent .nav a {
    color: white !important;
}

.talent .container {
    width: 90%;
    margin: 0 auto;
}

.talent .sectionOne {
    background-color: v.$l-blue;
}

.talent .sectionOne .grid > span {
    margin-left: 30px;
}

.searchContainer {
    display: grid;
    grid-template-columns: auto 200px;
    align-items: center;
    margin: 0px auto;
}

.talent h2,
.talent h1 {
    color: v.$d-blue2;
    margin: 0 auto;
    text-align: center;
}

.talent .sectionOne h1 {
    max-width: 700px;
    line-height: 1.7;
    font-size: 50px;
    text-align: left;
    color: white;
    margin: 0;
}

.talent .sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    width: 90%;
    margin: 0 auto;
    padding: 9% 0 0 0;
}

.talent .sectionOne .image {
   text-align: right;
   position: sticky
}

.talent .sectionOne .action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sectionOne h2.subtitle {
    margin: 30px auto 30px auto;
    font-weight: normal;
    text-align: left;
    color: white;
    font-size: 16px;
}


.talent .container {
    width: 90%;
    margin: 0 auto;
}

.talent .sectionOne{
    padding-bottom: 100px;
}

.talent .sectionThree {
    margin-top: 100px;
    padding: 50px 0 70px 0;
    background: linear-gradient(110.54deg, #8F00FF -25.69%, #8F07FA 7.86%, #96ABFD 83.53%);
}

.talent .sectionThree h2 {
    max-width: 90%;
    margin: 30px auto;
    text-align: center;
    color: white;
}

.talent h1 {
    text-align: center;
    color: white;
    font-size: 64px;
}

.talent .sectionTwo h2 {
    color:  v.$d-blue2;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: 0.005em;
    text-align: center;
}

.talent h3 {
    color: white;
    font-weight: normal;
    text-align: center;
    margin: 0px auto 30px auto;
}

.talent .sectionTwo p {
    font-size: 16px;
}

.talent .preFooter {
    padding: 100px 0 230px 0;
}

.talent .sectionTwo.container {
    width: 75%;
    padding-top: 70px;

}

.talent .sectionTwo .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    margin: auto;
}

.talent .grid p {
    width:100%
}


.talent .gridThreeCol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
    margin: 0 auto;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .talent .sectionOne .grid {
        display: block;
    }

    .talent .sectionOne .image {
        text-align: center;
        margin-top: 50px;
     }

     .talent .sectionTwo .grid span{
        display: block;
        margin: 0 auto;
     }

    .talent .sectionOne h2 {
        display: none;
    }

    .talent .sectionOne .action {
        justify-content: center;
    }

    .talent .sectionOne {
        padding-bottom: 20px;
    }

    .talent h3 {
        font-size: 16px;
    }

    .talent .sectionTwo .grid {
        display: block;
    }

    .talent .sectionTwo{
        padding-top: 0px;

    }

    .talent .sectionOne h1 {
        font-size: 34px;
        text-align: center;
        padding-bottom: 50px;
    }

    .talent .sectionTwo h2 {
        font-size: 24px;
    }

    .talent .sectionTwo.container {
        width: 85%;
    }

    .talent .gridThreeCol {
        display: block;
    }
}
