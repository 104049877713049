@use 'src/Sass/variables' as v;

/* Tooltip.css */
.tooltip {
  position: relative;
  display: inline-block; /* Or block, depending on your layout */
}

.tooltip .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position below the element */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

/* Arrow (Optional) */
.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.grid {
  background-color: #EEF1F4;
  border-radius: 24px;
  display: grid;
  grid-template-columns: 70% 30%;
  min-height: 80vh;
}

.horizontalGrid {
  background-color: #EEF1F4;
  border-radius: 24px;
  display: grid;
  grid-auto-flow: row;
  min-height: 80vh;
}

.row {
  min-height: 110px;
  margin-bottom: 30px;
}

.statusGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.statusGrid .statusCard {
  min-width: 200px;
}

.statusGrid h2 {
  font-size: 48px;
}

.threads {
  background-color: white;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  overflow: hidden;
}

.threads h3 {
  padding-left: 15px;
  padding-top: 15px;
}

.threadTile {
  display: flex;
  align-items: center;
  padding: 15px 0 15px 15px;
}

.threadTile img {
  height:40px;
  width: 40px;
  object-fit:cover;
  border-radius: 50%
}

.threadTile:hover {
  cursor: pointer;
}


.threadTile.active {
  background-color: v.$grey;
}

.threadsContainer {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.thread {
  text-align: center;
  display: block;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
}

.thread h4 {
  font-weight: 600;
  text-align: left;
}

.header {
  background-color: white;
  padding: 10px;
  position: absolute;
  top: 0;
  width: 100%;
  border-top-right-radius: 24px;
  border-left: 2px solid #EEF1F4;
}

.header img {
  height:40px;
  width: 40px;
  object-fit:cover;
  border-radius: 50%
}

.threadContent {
  width: 100%;
  padding: 10px;
}

.msgRec, .msgSent {
  display: flex;
}

.msgRec {
  justify-content: left;
}

.msgSent {
  justify-content: flex-end;
}

.msgRec p, .msgSent p {
  padding: 10px;
  border-radius: 10px;
  display:inline-block;
  max-width: 600px;
}

.msgRec p {
  background-color: white;
  border-bottom-left-radius: 0;
}

.msgSent p {
  text-align: end;
  background-color: v.$green;
  color: white;
  border-bottom-right-radius: 0;
}

.input, .mobileInput {
  padding-top: 10px;
  display: grid;
  grid-template-columns: auto 50px;
}

.mobileInput {
  bottom: 10px;
  width: 100%;
}

.mobile {
  display: none;
}

.login {
  width: 90%;
  margin: 0 auto;
}

.login .form {
  background-color: white;
  max-width: 576px;
  margin: 50px auto;
  padding: 50px;
  border-radius: 30px;
  box-shadow: 3px 3px 15px 3px rgba(108, 122, 137, 0.1);
}

.login .form button {
  width: 100%;
}

.login h2 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  .web {
    display: none;
  }

  .statusGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }

  .statusGridSolo {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 40px;
  }

  .statusGrid .statusCard {
    min-width: 50px;
    margin: 10px;
  }


  .statusGridSolo .statusCard {
    min-width: 50px;
    margin: 10px;
  }


  .sharedLink {
    height: 26px;
    overflow: hidden;
  }

  .mobile {
    display: block;
    position: relative;
  }

  .threadTile.active {
    background-color: white;
  }

  .grid {
    grid-template-columns: 60px auto;
  }

  .header {
    border-top-left-radius: 24px;
    border-left: 2px solid white;
  }

  .threadContent {
    overflow: scroll;
    max-height: 100%;
  }

  .header {
    position: relative;
  }

  .thread {
    height: calc(100% - 130px);
    padding-top: 0;
  }

  .login .form {
    padding: 20px;
  }

  .login h2 {
    font-size: 30px;
  }

  .login p {
    font-size: 16px;
  }

  .login .form {
    background-color: transparent;
    max-width: 95%;
    padding: 0px;
    box-shadow: none;
  }

}
