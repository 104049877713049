@use "./variables" as v;

button.btnFill,
a.btnFill {
    background-color: v.$blue ;
    border-color: v.$blue;
    white-space: nowrap;
    border-radius: 14px;
    color: white;
    text-decoration: none;
    padding: .375rem .75rem;
    font-size: 14px;
  }

button.btnFill.dark,
a.btnFill.dark {
  background-color: v.$d-blue ;
  border-color: v.$d-blue;
}

button.btnFill.dark:hover,
a.btnFill.dark:hover{
  background-color: v.$d-blue2 !important;
  border-color: v.$d-blue2 !important;
}

  button.btnFill.orange, a.btnFill.orange {
    background-color: v.$orange;
    border-color: v.$orange;
  }
  
  button.btnFill.orange:hover,
  a.btnFill.orange:hover{
    background-color: v.$d-orange !important;
    border-color: v.$d-orange !important;
  }

  button.btnFill.green:hover,
  a.btnFill.green:hover{
    background-color: v.$d-green !important;
    border-color: v.$d-green !important;
  }

  button.btnFill.white, a.btnFill.white {
    background-color:white;
    border-color: white;
    color: black;
  }

  button.btnFill.green, a.btnFill.green {
    background-color: v.$green;
    border-color: v.$green;
    color: white;
  }

  button.btnFill.purple, a.btnFill.purple {
    background-color: v.$purple;  
    border-color: v.$purple;  
    color: white;
  }

  button.btnFill.l-purple, a.btnFill.l-purple {
    background-color: rgba(118, 120, 237, 0.1);
    border-color: rgba(118, 120, 237, 0.1);
    color: v.$purple;  
  }

  button.btnFill.l-green, a.btnFill.l-green {
    background-color: rgba(41, 182, 151, 0.1);
    border-color: rgba(41, 182, 151, 0.1);
    color:v.$green;
  }

  button.btnFill.red, a.btnFill.red {
    background-color: #9c1111 !important;
    border-color: #9c1111 !important;
  }
  
  button.btnLink.red, a.btnLink.red {
    color: #9c1111 !important;
  }

  button.btnOutline.red, a.btnOutline.red {
    color: #9c1111 !important;
    border-color: #9c1111 !important;
  }

  button.btnOutline,
  a.btnOutline {
    background-color: transparent;
    border-color: v.$blue;
    white-space: nowrap;
    border-radius: 14px;
    color: v.$blue;
  }

  button.btnOutline:hover,
  a.btnOutline:hover {
    background-color: v.$blue;
    border-color: v.$blue;
  }

button.btnOutline.dark,
a.btnOutline.dark {
  border-color: v.$d-blue;
  color: v.$d-blue;
}

button.btnOutline.dark:hover,
a.btnOutline.dark:hover {
  background-color: v.$d-blue;
  color: white;
}

button.btnOutline.orange,
a.btnOutline.orange {
  border-color: v.$orange;
  color: v.$orange;
}

button.btnOutline.orange:hover,
a.btnOutline.orange:hover {
  background-color: v.$orange;
  color: white;
}

button.btnLink,
button.btnLink:focus,
a.btnLink:focus,
a.btnLink, a.page-link,   li.page-item.active .page-link,
li.page-item.disabled .page-link {
  background-color: transparent !important;
  border-color: transparent !important;
  color: v.$blue;
}

  button.btnLink,
  button.btnLink:hover,
  a.btnLink:hover,
  a.btnLink, a.page-link,   li.page-item.active .page-link, 
  li.page-item.disabled .page-link {
    background-color: transparent !important;
    border-color: transparent !important;
    color: v.$blue;
  }

  li.page-item.active {
    border-bottom: 3px solid v.$blue;
  }

  button.btnLink:not(:disabled):not(.disabled):active{
  background-color: transparent;
  border-color: transparent;
  color:v.$blue;
}
  
  button.btnLg, a.btnLg, button.lg, a.lg {
    font-size: 16px !important;
    height: 54px;
    padding-left: 20px;
    padding-right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }


  button.xl, a.xl {
    font-size: 16px;
    height: 64px;
    padding-left: 30px;
    padding-right: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  button.btnMd, button.md, a.md {
    font-size: 15px !important;
    padding: 10px 20px;
  }

  button.wide, a.wide {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  button.btn-link,
   a.btn-link,
   a.btnLink,
   button.btnLink {
    text-decoration: none;
  }

  a.btnLink.white , button.btnLink.white {
    color: white;
  } 

  a.btnLink.grey , button.btnLink.grey {
    color: #949DA6;
  } 

  a.btnLink.dblue , button.btnLink.dblue {
    color: v.$d-blue !important;
  } 

  button.btn {
    font-size: 14px;
  }