@use 'src/Sass/variables' as v;

.landing .nav a {
    color: white !important;
}

.landing .container {
    width: 90%;
    margin: 0 auto;
}

.landing .sectionOne {
    background-color: v.$l-blue;
    padding-bottom: 100px;
}

.landing .sectionOne .grid > span {
    margin-left: 30px;
}

.searchContainer {
    display: grid;
    grid-template-columns: auto 200px;
    align-items: center;
    margin: 0px auto;
}

.landing h2,
.landing h1 {
    color: v.$d-blue2;
    margin: 0 auto;
    text-align: center;
}

.landing .sectionOne h1 {
    max-width: 700px;
    line-height: 1.7;
    font-size: 50px;
    text-align: left;
    color: white;
    margin: 0;
}

.landing .sectionOne .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    width: 90%;
    margin: 0 auto;
    padding: 3% 0 0% 0;
}

.landing .sectionOne .image {
   text-align: right;
   position: sticky
}

.landing .sectionOne .action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.landing h2 {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.5;
}

.landing h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.005em;
    color:  v.$d-blue2;
}

.landing p {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
}

 .landing h3 {
    color: v.$d-blue2;
    font-size: 18px;
    text-align: center;
}

.sectionOne h2.subtitle {
    margin: 30px auto 30px auto;
    font-weight: normal;
    text-align: left;
    color: white;
    font-size: 16px;
}

.landing .search {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
}

.landing .stats {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing .sectionOne .stat p {
    margin: 0;
    text-align: center;
    white-space: nowrap;
    color: white;
}

.landing .stat p:first-of-type {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0.005em;
}

.landing .stat p:nth-of-type(2) {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.005em;
}

.landing .stat:nth-of-type(1) {
    border-right: 1px solid white;
}

.landing .stat {
    padding-left: 2em;
    padding-right: 2em;
}

.landing .stat:first-of-type {
    padding-left: 0em;
}

.landing button.inactive {
    background-color: #F9F9FA;
    border: 1px solid #F9F9FA;
    color: v.$d-blue2;
}

.landing .sectionTwo .container {
    background-color: white;
    border-radius: 24px;
    margin-top: -5%;
    padding: 70px 0 70px 0;
}

.landing .sectionTwo .grid {
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    margin-bottom: 50px;
}


.landing .sectionTwo .step {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.step img {
    max-height: 70px;
    margin-right: 30px;
}

.step {
    max-width: 400px;
    margin-right: 100px ;
    margin-bottom: 50px;
}

.step.concierge {
    max-width: 800px ;
}

.stepContainer {
    display: flex;
    justify-content: center;
}

.stepContainer img {
    max-height: 300px;
}

.landing .sectionThree .grid{
    display: grid;
    grid-template-columns: 50% 50%;
    width: 85%;
    padding: 150px 0 0 0;
    position: relative;
    column-gap: 60px;
}

.landing .sectionThree .grid:nth-of-type(2) div:first-of-type{
    grid-column: 2;
    grid-row: 1;
}

.landing .sectionThree .grid:nth-of-type(2) div:nth-of-type(2){
    grid-column: 1;
    grid-row: 1;
}

.sectionThree .img2 {
    position: absolute;
}

.landing .sectionThree h2,
.landing .sectionThree h3,
.landing .sectionFour h2{
    text-align: left;
}

.landing .sectionThree .stat p {
    text-align: left;
    width: 320px;
}

.landing .sectionFour {
    background-color: white;
    padding: 100px 0 150px 0;
    position: relative;
}

.landing .sectionFour .grid{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 30px;
    text-align: center;
}

.uniTile p:first-of-type {
    color: v.$d-blue2;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.uniTile a {
    color: v.$d-blue;
    border-color: v.$d-blue;
    padding: 10px 15px;
}

.uniTile img {
    border-radius: 20px;
    width: 100%;
    height: 250px;
    object-fit: cover;
}


.sectionFour a.discover {
    position: absolute;
    top: 100px;
    right: 10%;
}

.landing .sectionFour .school {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: v.$d-blue2;
    letter-spacing: 0.005em;
}

.landing .sectionFive {
    background-image: url("https://cdn.nxstep.co/landing_bg_2.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 150px 0 0px 0;
    background-color: white;
}

.landing .sectionFive .grid {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 50px;
}

.sectionFive .quote {
    font-size: 20px;
    line-height: 1.5;
    color: white;
    margin-top: 30px;
}

.preFooter {
    padding: 100px 0 100px 0;
}

.landing .sectionSix .grid {
    display: grid;
    margin: 0 auto;
    width: 80%;
    grid-template-columns: 50% 40%;
    margin-top: 50px;
    column-gap: 70px;
}

.landing .sectionSix h4 {
    font-weight: 600;
    font-size: 38px;
    line-height: 54px;
}

.sectionSix img {
    max-height: 600px;
}

.crop {
    overflow: hidden;
    width: 200px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
}

.crop img {
    margin: 0 auto;
    display: block;
    position: relative;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 850px) {
    .landing .sectionOne .image {
        text-align: center;
        margin-top: 50px;
     }

     .landing .sectionOne .action {
        justify-content: center;
    }

    .landing .sectionOne .grid {
        display: block;
    }

    .landing .sectionOne h2 {
        display: none;
    }

    .landing .sectionThree h2,
    .landing .sectionThree h3,
    .landing .sectionFour h2{
        text-align: center;
    }


    .landing .sectionSix h4 {
        font-size: 32px;
    }

    .landing .sectionOne h1 {
        font-size: 35px;
        text-align: center;
        width: 95%;
        padding-bottom: 20px;
    }

    .landing .sectionOne .grid span {
        width: 95%;
        margin: 0 auto;
    }

    .landing .searchContainer {
        width: 96%;
        padding-bottom: 20px;
    }

    .landing .sectionOne {
        padding-bottom: 50px;
    }

    .landing .search,
    .landing .sectionTwo .grid,
    .landing .sectionThree .grid,
    .landing .sectionFour .grid,
    .landing .sectionFive .grid,
    .landing .sectionSix .grid {
        display: block;
    }

    .searchContainer,  .searchContainer .join {
        display: block;
        margin: 20px auto;
    }

    .searchContainer a {
        margin-left: 0 !important;
    }

    .landing .stat:nth-of-type(2) {
        border-left: none !important;
        border-right: none !important;
    }

    .landing .stats {
        padding-top: 30px;
    }

    .landing .stat {
        margin-bottom: 30px;
    }

    .landing .stat span{
        min-width: 70%;
    }


    .landing .stat p:first-of-type, .subtitle {
        font-size: 24px;
    }

    .landing .sectionOne .stat p {
        white-space: normal;
    }

    .landing .sectionOne .search button,
    .landing .sectionSix button {
        position: relative;
        width: 100%;
    }

    .landing .sectionTwo .container {
        background-color: white;
        border-radius: 0;
        margin-top: 0;
    }

    .landing .stepContainer {
        display: block;
        width: 95%;
        margin: 0 auto;
    }

    .landing .step {
        margin: 20px auto 0 auto;
    }

    .landing .step img {
        display: none;
    }

    .landing .step  {
        text-align: center;
    }

    .landing .sectionTwo .container {
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 50px 0px;
    }

    .landing h3 {
        font-size: 16px;
    }

    .landing .sectionThree .grid {
        padding: 50px 0;
        width: 90%;
    }

    .landing .sectionThree button,
    .landing .sectionThree a,
    .landing .sectionSix a{
        display: block;
        margin: 0 auto;
    }

    .landing .sectionThree .stat p {
        width: auto;
    }

    .landing .sectionFour a.discover {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        position: relative;
        right: 0;
        top: 0;
    }

    .grid img {
        width: 100%;
    }

    .sectionFive .quote {
        font-size: 16px;
        line-height: 1.2;
    }
}
