@use 'src/Sass/variables' as v;


.content {
    padding: 20px;
}

span.link {
    font-size: 16px;
    font-weight: normal;
}

span.link > svg {
    margin-right: 20px;
}

.header {
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    padding: 10px;
}


.header .avi {
    height: 50px;
    width: 50px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
}

.header .profile:hover {
    cursor: pointer;
}


@media only screen and (max-width: 960px) { 
    .content { 
        padding: 10px;
        height: calc(100vh - 120px);
    }

    span.profile, .searchContainer {
        display: none !important;
    }

    .title {
        display: block;
        color: white;
        text-align: center;
        font-size: 20px;
    }
    
    .header { 
        display: block;
        background-color: v.$d-green;
        padding: 20px 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        position: relative;
        margin-bottom: 20px;
    }

    .notifications {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}