@use 'src/Sass/variables' as v;

.infoCard .avi {
    height: 70px;
    width: 70px;
    margin-top: -60px;
}

.infoCard .loading {
    height: 70px;
    width: 70px;
    margin-top: -60px;
}

.profileGrid {
    grid-template-columns: 33% 60%;
    display: grid;
    column-gap: 20px;
}

.editProfileGrid {
    grid-template-columns: 50% 50%;
    display: grid;
    column-gap: 30px;
    width: 95%;
}

.gridTwoCol {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    row-gap: 30px;
}

.cardImg {
    height: 120px;
    object-fit: cover; 
    filter: brightness(90%);
    z-index: -2;
  }

.mentorProfile .avi, .mentorEditProfile .avi {
    background: url('../../../Static/default-avi.png');
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-top: -60px;
    z-index: 0;
    position: relative;
}

.mentorProfile .loading, .mentorEditProfile .loading {
    background-color: white;
    height: 80px;
    width: 80px;
    background-position: center;
    background-size: contain;
    border-radius: 50%;
    margin-top: -60px;
    z-index: 0;
    position: relative;
}

.mentorEditProfile .avi {
    margin-top: 0;
}

.mentorEditProfile .loading {
    margin-top: 0;
}

.editTile {
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(212, 221, 230, 0.2);
}

.dayInput > div {
    width: 100%;
}

.row {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;    
    padding: 0px;
}

.connectionBtnGrp {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;    
    padding: 0px;
}

.purple {
    color: v.$purple;  
    background-color: rgba(118, 120, 237, 0.1) !important;  
}

.green {
    color: v.$green;  
    background-color: rgba(41, 182, 151, 0.1) !important;
}

.red {
    color: v.$red !important;  
    background-color: rgba(226, 101, 101, 0.1) !important;
}

@media only screen and (max-width: 960px) { 
    .profileGrid, .editProfileGrid, .gridTwoCol {
        display: block;
    }
}